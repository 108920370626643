/* barlow-condensed-regular - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/barlow-condensed-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Barlow Condensed Regular'), local('BarlowCondensed-Regular'),
  url('../../fonts/barlow-condensed-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/barlow-condensed-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/barlow-condensed-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/barlow-condensed-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/barlow-condensed-v3-latin-regular.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-condensed-italic - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/barlow-condensed-v3-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Barlow Condensed Italic'), local('BarlowCondensed-Italic'),
  url('../../fonts/barlow-condensed-v3-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/barlow-condensed-v3-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/barlow-condensed-v3-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/barlow-condensed-v3-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/barlow-condensed-v3-latin-italic.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-condensed-600 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/barlow-condensed-v3-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Barlow Condensed SemiBold'), local('BarlowCondensed-SemiBold'),
  url('../../fonts/barlow-condensed-v3-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/barlow-condensed-v3-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/barlow-condensed-v3-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/barlow-condensed-v3-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/barlow-condensed-v3-latin-600.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-condensed-600italic - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: italic;
  font-weight: 600;
  src: url('../../fonts/barlow-condensed-v3-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Barlow Condensed SemiBold Italic'), local('BarlowCondensed-SemiBoldItalic'),
  url('../../fonts/barlow-condensed-v3-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/barlow-condensed-v3-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/barlow-condensed-v3-latin-600italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/barlow-condensed-v3-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/barlow-condensed-v3-latin-600italic.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}

/* work-sans-300 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/work-sans-v4-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Work Sans Light'), local('WorkSans-Light'),
  url('../../fonts/work-sans-v4-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/work-sans-v4-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/work-sans-v4-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/work-sans-v4-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/work-sans-v4-latin-300.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/work-sans-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Work Sans'), local('WorkSans-Regular'),
  url('../../fonts/work-sans-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/work-sans-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/work-sans-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/work-sans-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/work-sans-v4-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/work-sans-v4-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Work Sans Medium'), local('WorkSans-Medium'),
  url('../../fonts/work-sans-v4-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/work-sans-v4-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/work-sans-v4-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/work-sans-v4-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/work-sans-v4-latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/work-sans-v4-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
  url('../../fonts/work-sans-v4-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/work-sans-v4-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/work-sans-v4-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/work-sans-v4-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/work-sans-v4-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-700 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/work-sans-v4-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Work Sans Bold'), local('WorkSans-Bold'),
  url('../../fonts/work-sans-v4-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/work-sans-v4-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/work-sans-v4-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/work-sans-v4-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/work-sans-v4-latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
}
