@mixin btn-color($color) {
  background-color: $color;
  &:hover,
  &:focus {
    background-color: lighten($color, 5%);
    box-shadow: 0 .25rem 1rem rgba($color, .33);
  }
  &:active {
    background-color: darken($color, 5%);
    box-shadow: none;
    transition: none;
  }
}
