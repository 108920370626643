// BEGIN: Error Page
body.error-page {
  .page-wrapper {
    padding: 1rem;
  }

  .page-wrapper,
  .container {
    height: 100%;
  }

  .logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: auto;
    height: 2.5rem;
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;

    .error-code {
      display: inline-block;
      position: relative;
      font-size: 10rem;
      color: $brandColor;
      margin-bottom: 2rem;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: .75rem;
        background-color: lighten($bgColor2, 6%);
      }
    }

    .error-message {
      font-size: 1.5rem;
      font-family: 'Work Sans', Helvetica, sans-serif;
      font-weight: 400;
      color: #fff;
      margin-bottom: 2rem;
    }

    .error-image {
      position: absolute;
      right: 50%;
      bottom: 0;
      transform: translateX(42%);
      width: 112.5%;
      opacity: .125;
      z-index: -1;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  @media screen and (min-width: 50rem) {
    .container {
      .error-image {
        right: 0;
        top: 50%;
        bottom: unset;
        transform: translateY(-50%);
        width: 66%;
        opacity: .5;
      }
    }
  }
}

// END: Error Page