// BEGIN Dropdowns
.collapse.show {
  visibility: visible;
}

.bootstrap-select .dropdown-menu li a {
  display: block;
  padding: 7px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #99abb4;
  white-space: nowrap;
}

.dropdown-menu {
  background-color: #212529;
  &.inner {
    display: block;
  }
}

a[aria-selected=true] {
  background-color: $brandColor;
}

.btn-group.bootstrap-select {
  width: 100% !important;

  .btn.dropdown-toggle {
    color: gray;
    background-color: $bgColor;
    font-family: 'Work Sans', Helvetica, sans-serif;
    color: white;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    transition-duration: 0.2s;
    border: 1px solid $bgColor;
    border-radius: 5px;
    padding: 0;
    padding-left: 15px;
    text-transform: capitalize;

    &:active {
      outline: none;
    }
    &:focus {
      outline: none;
    }

    &::after {
      display: none !important;
    }
  }
}

// END Dropdowns
