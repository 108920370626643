.video-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); // 100%
  grid-gap: 1.5rem;
  margin-bottom: 1.5rem;
  height: auto;
  min-height: 1.5rem;
  @media screen and (min-width: 30rem) {
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr)); // 50%
  }
  @media screen and (min-width: 90rem) {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr)); // 33.33%
  }

  &.large {
    @media screen and (min-width: 30rem) {
      grid-template-columns: repeat(auto-fill, minmax(45%, 1fr)); // 50%
    }
    @media screen and (min-width: 60rem) {
      grid-template-columns: repeat(auto-fill, minmax(30%, 1fr)); // 33.33%
    }
    @media screen and (min-width: 90rem) {
      grid-template-columns: repeat(auto-fill, minmax(20%, 1fr)); // 33.33%
    }
  }
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.video-showcase-holder {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  margin-bottom: 1rem;
  overflow: visible;

  a {
    color: #fff;

    &:hover {
      color: $brandColor;
      text-decoration: none;
    }
  }

  .thumbnail {
    display: block;
    position: relative;
    padding-top: 56.25%;
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: .25rem;
    cursor: pointer;
    transition: all 500ms ease;

    .privacy-status-wrapper {
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      width: 1.75rem;
      height: 1.75rem;
      .privacy-status {
        overflow: hidden;
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 1.75rem;
        height: 1.75rem;
        border-radius: .25rem;
        &::after {
          content: '';
          z-index: 1;
          width: 50px;
          height: 50px;
          position: absolute;
          top: -30px;
          right: -30px;
          transform: rotateZ(45deg);
        }
        &.public {
          &::after {
            background-color: #30e43e;
            box-shadow: 0 0 14px 0 rgba(46, 204, 113, 0.6)
          }
        }
        &.unlisted {
          &::after {
            background-color: #e4e234;
            box-shadow: 0 0 14px 0 rgba(228, 226, 52, 0.6)
          }
        }
        &.private {
          &::after {
            background-color: #fd3723;
            box-shadow: 0 0 14px 0 rgba(231, 76, 60, 0.6)
          }
        }
      }
      .tooltip-icon {
        position: relative;
        top: -2rem;
        left: -50%;
        text-transform: capitalize;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .25;
      background-image: url('/images/image_pattern-overlay.png');
      transition: all 300ms ease;
      @media screen and (min-width: 50em) {
        opacity: .5;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      visibility: hidden;
      opacity: 0;
      transition: all 300ms ease;
      @media screen and (min-width: 50em) {
        visibility: visible;
        opacity: .33;
      }
    }

    @media screen and (min-width: 50em) {
      &:hover {
        background-size: 105%;

        &::before {
          opacity: .25;
        }

        &::after {
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }

  .video-info {
    display: flex;
    flex-wrap: wrap;
    padding-top: .5rem;
    width:100%;
    h5 {
      position: relative;
      width: 100%;
      margin: .25rem 0 .25rem;
      font-family: 'Work Sans', sans-serif;
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
    }
    .video-desc {
      width:100%;
      font-size:13px;
      .description {
        width:100%;
        margin:0;
      }
    }
    .published {
      font-size:13px;
      margin:0;
    }
  }

  .stat-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top:.5rem;
    .stat-tag {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      height: 1.5rem;
      margin: 0 .5rem .5rem 0;
      padding: 0 .5rem;
      width: auto;
      color: white;
      font-family: 'Work Sans', sans-serif;
      font-size: .75rem;
      font-weight: 500;
      background: lighten($bgColor2, 3%);
      border-radius: 100rem;
      i {
        font-size: 1rem;
        margin-right: .25rem;
        color: $brandColor;
      }
    }
  }

  &.showMore {
    position: relative;
    padding-bottom: 56.25%;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(255, 153, 13, 0.5)), url('~@/images/backend/more-content-background.jpg') center no-repeat;
    background-size: 100%;
    transition: all 300ms ease;
    border-radius: .25rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .5;
      background-image: url('~@/images/frontpage/image_pattern-overlay.png');
    }

    a {
      z-index: 1;
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-size: 110%;

      &::after {
        visibility: visible;
        opacity: .7;
      }
    }
  }
}
