// BEGIN: Original
ul.wysihtml5-toolbar {
  margin: 0;
  padding: 0;
  display: block;
  border: 1px solid #e3e3e3;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 0;

  &::after {
    clear: both;
    display: table;
    content: "";
  }

  > li {
    float: left;
    display: list-item;
    list-style: none;
    padding: 0;
    margin: 0 5px 0 0;
    > a.btn,
    .btn-group > a.btn { // Toolbar buttons
      padding: 12px 15px;
      background: inherit;
      font-size: inherit;
      border-radius: 0;
      color: inherit;
      &.wysihtml5-command-active {
        background-image: none;
        box-shadow: none;
        outline: 0;
      }
      &.wysihtml5-action-active,
      &.wysihtml5-command-active {
        background-color: lighten($bgColor2, 10%);
        color: $brandColor;
      }
      &:hover {
        color: lighten($bodyText, 15%);
      }
    }
  }

  a {
    margin: 0;

    &[data-wysihtml5-command=bold] {
      font-weight: bold;
    }

    &[data-wysihtml5-command=italic] {
      font-style: italic;
    }

    &[data-wysihtml5-command=underline] {
      text-decoration: underline;
    }
  }
}

ul.wysihtml5-commands-disabled .dropdown-menu {
  display: none !important;
}

ul.wysihtml5-toolbar .fa {
  margin: 0;
  font-size: 14px;
}

.wysihtml5-textarea {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  &:focus {
    border-top: none;
    background: #fff;
  }
}

// END: Original

// BEGIN: Custom
ul.wysihtml5-toolbar {
  background-color: lighten($bgColor2, 5%);
  font-size: 14px;
  border: none;
  > li {
    > a.btn,
    .btn-group > a.btn {
      box-shadow: none;
      transform: none !important;
    }
  }
  .btn {
    .fa {
      font-family: "ionicons", serif;
      font-style: normal;
      font-size: 16px;
    }
    .fa-list::before {
      content: "\f454";
    }
    .fa-th-list::before {
      content: "\f31b";
    }
    .fa-outdent::before {
      content: "\f117";
    }
    .fa-indent::before {
      content: "\f119";
    }
    .fa-link::before {
      content: "\f22e";
    }
    .fa-image::before {
      content: "\f19c";
    }
    .fa-pencil::before {
      content: "\f157";
    }
  }
  .dropdown-menu {
    background-color: lighten($bgColor2, 5%);
    li {
      padding: .25rem 0 .25rem 1rem;
      font-size: 14px;
      &:hover {
        background-color: lighten($bgColor2, 10%);
      }
      a {
        color: $bodyText;
        &:hover {
          color: $brandColor;
          text-decoration: none;
        }
      }
    }
  }
}

// END: Custom
