// BEGIN: Topbar
.topbar {
  width: 100%;
  z-index: 999;
  background-color: $bgColor2;
  flex-shrink: 0;
  border-bottom: 1px solid darken(#121B1D, 5%);

  .stat-bar {
    width: 100vw;
    height: 40px;
    display: none;
    background-color: #24252c;
    visibility: visible;
  }

  > .top-navbar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    height: $topbarHeight;
    padding: 0;

    .holders {
      visibility: hidden;
      display: flex;

      > div .revenue-holder {
        background-color: $brandColor;

        &:hover {
          text-decoration: none;
          background-color: darken($brandColor, 6%);
        }
      }

      .subscriber-holder,
      .revenue-holder {
        display: flex;
        flex-shrink: 1;
        align-items: center;
        width: auto;
        min-width: 8rem;
        height: $topbarHeight;
        padding: 0 1rem;

        h4 {
          display: flex;
          flex-wrap: wrap;
          height: auto;
          margin: 0;
          color: white;
          font-family: 'Work Sans', sans-serif;
          font-size: 1rem;
          line-height: 1rem;

          small {
            display: block;
            width: 100%;
            font-size: .75rem;
          }

          svg {
            width: 1rem;
            height: 1rem;
            margin-left: .25rem;
          }
        }
      }

      .subscriber-holder {
        background-color: lighten($bgColor2, 3%);
      }
    }

    .navbar-collapse {
      justify-content: flex-end;
      flex-basis: 0;
    }
  }

  .locale-selection {
    > a {
      display: flex;
      align-items: center;
      height: $topbarHeight;
      color: $bodyText;
    }
  }

  .topbar-notifications.dropdown {
    font-size: 1.7rem;

    /**
     * ----------------------------------------
     * animation shadow-drop-center
     * ----------------------------------------
     */
    @-webkit-keyframes shadow-drop-center {
      0% {
        -webkit-text-shadow: 0 0 10px rgba(255, 153, 13, 0.0);
        text-shadow: 0 0 10px rgba(255, 153, 13, 0.0);
      }
      100% {
        -webkit-text-shadow: 0 0 10px rgba(255, 153, 13, 1.0);
        text-shadow: 0 0 10px rgba(255, 153, 13, 1.0);
      }
    }
    @keyframes shadow-drop-center {
      0% {
        -webkit-text-shadow: 0 0 10px rgba(255, 153, 13, 0.0);
        text-shadow: 0 0 10px rgba(255, 153, 13, 0.0);
      }
      100% {
        -webkit-text-shadow: 0 0 10px rgba(255, 153, 13, 1.0);
        text-shadow: 0 0 10px rgba(255, 153, 13, 1.0);
      }
    }

    .ion-ios-mail-unread {
      -webkit-animation: shadow-drop-center 1.5s cubic-bezier(0.445, 0.050, 0.550, 0.950) infinite alternate forwards;
      animation: shadow-drop-center 1.5s cubic-bezier(0.445, 0.050, 0.550, 0.950) infinite alternate forwards;
    }

    .dropdown-menu {
      padding: 0;

      .dropdown-divider {
        border-top: 1px solid #808391;
      }

      .notifications-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .75rem 1.25rem;
        font-size: small;
        color: white;
      }


      .nav-link.view-all {
        text-align: center;
        border-top: 1px solid #80839140;
        padding: .75rem 1.25rem;
      }

      .dropdown-item {
        &.notification-item {
          line-height: 1.25rem;
          padding: .55rem 1.25rem;
          border-top: 1px solid #0000002e;
        }

        &.none-available {
          font-size: small;
          text-align: center;
          padding: 2rem 5rem;
        }
      }
    }
  }

  .topbar-profile {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    > li {
      > a:hover,
      > a[aria-expanded="true"] {
        background-color: lighten($bgColor2, 5%);
      }
    }

    .dropdown {
      position: relative;

      .dropdown-menu {
        position: absolute;
        display: block;
        top: 100%;
        left: unset;
        right: 0;
        visibility: hidden;
        opacity: 0;
        margin: 0;
        min-width: 100%;
        width: max-content;
        height: auto;
        background-color: lighten($bgColor2, 3%);
        border-radius: .25rem;
        box-shadow: 0 .5rem 2rem rgba($bgColor, .75);
        transform: translateY(0);
        transition: all 250ms ease;

        .dropdown-item {
          color: $bodyText;
          line-height: 2.5rem;
          padding: 0 1.25rem;

          i {
            display: inline-block;
            width: 1rem;
            margin-right: .25rem;
            text-align: center;

            svg {
              height: 1rem;
              margin-bottom: -.125rem;

              path {
                fill: $bodyText;
              }
            }
          }

          &:hover,
          &:focus,
          &:active {
            color: $brandColor;
            background-color: lighten($bgColor2, 6%);

            svg {
              path {
                fill: $brandColor;
              }
            }
          }
        }
      }

      &.show {
        .dropdown-menu {
          visibility: visible;
          opacity: 1;
          transform: translateY(1rem);
        }
      }
    }

    #profile_button {
      &::after {
        display: none;
      }

      height: $topbarHeight;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .profile-info {
        display: none;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-right: 1rem;

        h4 {
          margin-bottom: .125rem;
          font-family: 'Work Sans', Helvetica, sans-serif;
          text-align: right;
          font-size: 1rem;
          color: #FFFFFF;
        }

        span {
          font-size: 10.56px;
          color: #7A7A7A;
          letter-spacing: 0.12px;
          text-align: right;
        }
      }

      .profile-picture {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-size: cover;
        background-color: white;
        background-position: center;
      }
    }
  }
}

// END: Topbar
