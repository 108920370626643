ol.breadcrumb {
  background-color:transparent;
  padding: 0;
  margin-left:1rem;
  border-left: $brandColor 3px solid;
  border-radius:0;
  li {
    font-size:.75rem;
    &:first-child {
      padding-left:.75rem;
    }
    &.active {
      color:$brandColor;
    }
    a {
      color:$bodyText;
    }
  }
}