.sweet-alert {
  .sa-icon.sa-custom {
    background-size: cover !important;
    margin: 0 auto !important;
    border-radius: .25rem !important;
  }
  h2 {
    margin-top: 0;
  }
  span.price {
    display: block;
    margin-top: 10px;
    font-size: 1.5rem;
    font-weight: 600;
    svg path {
      fill: $brandColor;
    }
  }
  .sa-button-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }
}

.swal-modal {
  background-color: #1e1f25;
  .swal-text {
    color: rgba(255, 255, 255, 0.64)
  }
  .swal-title {
    color: rgb(215, 170, 0);
  }
  .swal-button {
    background-color: #5a5a5b;
    color: white;
    &:not([disabled]):hover {
      background: #323539;
    }
    &:focus {
      box-shadow: none;
    }
    &--confirm {
      background-color: #d7aa00;
    }
  }
}