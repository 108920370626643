// BEGIN: Checkboxes
label.custom-checkbox {
  position: relative;
  padding-left: 2rem;
  cursor: pointer;

  // Default checkbox
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  // Check mark
  .control_indicator {
    position: absolute;
    top: .125rem;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    background: lighten($bgColor2, 12%);
    border-radius: .25rem;

    // Checkbox tick
    &::after {
      position: absolute;
      display: none;
      content: '';
      top: 3px;
      left: 7px;
      width: 7px;
      height: 12px;
      transform: rotate(45deg);
      border: solid #fff;
      border-width: 0 3px 3px 0;
    }
  }

  // Checked state
  input:checked {
    ~ .control_indicator {
      background: $brandColor;
    }
  }

  // Hover states
  &:hover {
    color: lighten($bodyText, 15%);
    .control_indicator {
      background: lighten($bgColor2, 21%);
    }

    // While checked
    input:not([disabled]):checked ~ .control_indicator,
    input:checked:focus ~ .control_indicator {
      background: darken($brandColor, 12%);
    }
  }

  // Disabled state
  input:disabled {
    ~ .control_indicator {
      pointer-events: none;
      cursor: auto;
      background: rgba($bodyText, .5);
      &::after {
        border-color: $bodyText;
      }
    }
  }

  // Show check mark
  input:checked ~ .control_indicator:after {
    display: block;
  }

}

// END: Checkboxes
