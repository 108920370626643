.card {
  background: $bgColor2;
  margin-bottom: 2rem;

  &-banner-header {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100px;
    color: white;

    .title-holder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }

  &-body {
    .card-footer {
      font-family: 'Work Sans', Helvetica, sans-serif;
      font-size: .75rem;
      color: $brandColor;
      font-weight: 500;
    }

    .card-title {
      margin-bottom: .75rem;
      font-family: 'Work Sans', Helvetica, sans-serif;
      font-size: 16px;
    }

    .card-subtitle {
      color: $bodyText;
      font-family: 'Work Sans', Helvetica, sans-serif;
      font-size: 14px;
      margin-bottom: 1.5rem;
      font-weight: 400;
    }

    p.card-text {
      font-family: 'Work Sans', Helvetica, sans-serif;
      font-size: .75rem;
      color: $bodyText;
    }

    .info-holder {
      background: #110f0f;
      display: flex;
      justify-content: space-between;
      flex-flow: row;
      padding: 10px;
      color: white;
    }
  }

  &.video-margin {
    margin-bottom: 8px !important;

    .little-profile {
      .row {
        border-top: none !important;
        padding-top: 10px !important;
        margin-top: 0px !important;

        .col-xl-2 {
          border-left: 1px solid #2A3335;

          &:first-child {
            border: none;
          }
        }
      }

      .slimScrollDiv {
        margin-top: 20px;
      }

      #description {
        overflow: hidden;
        font-family: 'Work Sans', sans-serif;
        font-size: .75rem;
        line-height: 1.5rem; /* fallback */
      }
    }
  }

  .chart-holder {
    height: 350px;
    width: 100%;
    margin-top: 35px;
  }

  .message-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    min-height: 1px;

    .message-widget > a {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      width: 100%;
      padding: 1rem .5rem;
      border-bottom: 1px solid rgba($bodyText, .25);

      &:last-child {
        border: none;
      }

      &:hover {
        border-radius: .25rem;
        background-color: lighten($bgColor2, 5%);
      }
    }

    .profile-img {
      img {
        margin: 0;
      }

      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-right: 1rem;
    }

    .mail-contnet {
      h5 {
        margin-bottom: 0;
      }

      span {
        display: block;
      }

      .mail-desc, .time {
        color: gray;

        &.stats {
          color: $brandColor;
        }
      }
    }
  }

  .form-group {
    // TODO: Go over global typography styles to make this unnecessary
    //label {
    //  color: white;
    //  font-family: 'Work Sans', Helvetica, sans-serif;
    //  font-size: 13px;
    //}

    select {
      color: gray;
      background-color: $bgColor;
    }

    input {
      color: gray;
      background-color: $bgColor;
      font-family: 'Work Sans', Helvetica, sans-serif;
      color: white;
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      padding-left: 15px;
      transition-duration: 0.2s;
      border: 1px solid $bgColor;
      border-radius: 5px;

      &:active {
        outline: none;
        transition-duration: 0.2s;
        border: 1px solid $brandColor;
      }

      &:focus {
        outline: none;
        transition-duration: 0.2s;
        border: 1px solid $brandColor;
      }
    }
  }
}
