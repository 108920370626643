/* TODO: Implement using bootstrap variables. */

/* Quick fixes */
.btn-group {
  .btn {
    margin-right: 0;
  }
}

.dropdown-toggle:hover {
  transform: none;
}

.btn {
  margin-right: .5rem;
  border: none;
  cursor: pointer;
  transition: all 250ms ease;
  transform: translateY(0);
  box-shadow: none;
  font-weight: 500;

  &.btn-primary {
    color: white;
  }

  &:last-child {
    margin-right: 0;
  }

  &:disabled, &.disabled {
    pointer-events: none !important;
    background-color: $btnSecondaryBg !important;
    border: none !important;
    color: white;
  }

  &:hover {
    color: white;
    box-shadow: 0 .25rem 1rem rgba($btnDefaultBg, .33);
    transform: translateY(-.25rem);
  }

  &:active {
    transform: translateY(0);
    background-color: darken($bodyText, 5%);
    box-shadow: none;
    transition: none;
  }

  &:focus, &:focus:active {
    box-shadow: none;
  }

  &-primary {
    @include btn-color($btnPrimaryBg);
  }

  &-secondary {
    @include btn-color($btnSecondaryBg);

    &:hover,
    &:focus {
      color: $brandColor;
      box-shadow: 0 .25rem 1rem rgba($bgColor, .33);
    }

    &:active {
      color: darken($brandColor, 5%);
    }
  }

  &-success {
    @include btn-color($btnSuccessBg);
  }

  &-danger {
    @include btn-color($btnDangerBg);
  }

  &.btn-static {
    transform: none !important;
  }

  &.btn-loading {
    background-color: $btnSecondaryBg;
  }

  &-spex {
    position: relative;
    height: 3.5rem;
    line-height: 1.5;
    vertical-align: middle;
    font-family: 'Barlow Condensed', Helvetica, sans-serif;
    text-transform: uppercase;
    color: $brandColor !important;
    font-size: 1.5em;
    background: transparent !important;
    transition: all 250ms ease;
    padding-left: 3rem;
    padding-right: 3rem;

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    .BorderLeftTopCorner,
    .BorderRightBottomCorner,
    .BorderLeftBottomCorner,
    .BorderRightTopCorner {
      &::before, &::after {
        content: "";
        position: absolute;
        background: $brandColor;
        transition: all 0.5s ease;
      }
    }

    .BorderLeftTopCorner {
      &::before {
        width: .75rem;
        height: 1px;
        top: 0;
        left: 0;
      }

      &::after {
        width: .75rem;
        height: 1px;
        bottom: 0;
        right: 0;
      }
    }

    .BorderRightBottomCorner {
      &::before {
        top: 0;
        left: 0;
        width: 1px;
        height: .5rem;
      }

      &::after {
        bottom: 0;
        right: 0;
        width: 1px;
        height: .5rem;
      }
    }

    .BorderLeftBottomCorner {
      &::before {
        bottom: 0;
        left: 0;
        width: 1px;
        height: 25%;
      }

      &::after {
        bottom: 0;
        left: 0;
        width: 75%;
        height: 1px;
      }
    }

    .BorderRightTopCorner {
      &::before {
        top: 0;
        right: 0;
        width: 1px;
        height: 25%;
      }

      &::after {
        top: 0;
        right: 0;
        width: 75%;
        height: 1px;
      }
    }

    &:hover, &:focus {
      transform: none;
      box-shadow: none;

      .BorderLeftTopCorner {
        &::before,
        &::after {
          width: calc(100% - 1rem);
        }
      }

      .BorderRightBottomCorner {
        &::before,
        &::after {
          height: 80%;
        }
      }

      .BorderRightTopCorner,
      .BorderLeftBottomCorner {
        &::after {
          width: 0;
        }
      }

      .BorderLeftBottomCorner,
      .BorderRightTopCorner {
        &::before {
          height: 0;
        }
      }
    }
  }

  &-nocorner-wrap {
    overflow: hidden;
    position: relative;
    border-radius: .1rem;

    &:hover {
      .skew-fix {
        transform: skew(0deg);
      }

      .parallelogram {
        transform: skew(0deg);
      }
    }
  }

  &-back {
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: $bodyText;
    font-family: "Barlow Condensed", Helvetica, sans-serif;
  }
}

.dt-button {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  margin-right: 1px;
  padding: 0 1rem;
  border: none;
  color: white;
  font-family: 'Work Sans', Helvetica, sans-serif;
  font-size: .75rem;
  text-decoration: none;
  background-color: $btnSecondaryBg;
  cursor: pointer;
  transition-duration: 250ms;

  &:first-child {
    border-radius: 2rem 0 0 2rem;
  }

  &:last-child {
    border-radius: 0 2rem 2rem 0;
  }

  &:hover {
    color: white;
    text-decoration: none;
    background-color: $brandColor;
    box-shadow: 0 0 1rem rgba($brandColor, .5);
  }
}

$partnerInviteReady: #e67e22;
$partnerInviteWaiting: #f1c40f;
$partnerInviteInvited: #3498db;
$partnerInviteLinked: #2ecc71;
$partnerInviteRejected: #e74c3c;

a, span, option, input, div {
  &.partner-invite-status {
    color: gray;

    &:hover {
      color: lighten(gray, 5%)
    }

    &.actionRequired, &.readyForMcnInvite {
      color: $partnerInviteReady !important;

      &:hover {
        color: lighten($partnerInviteReady, 10%) !important;
      }
    }

    &.waitingForInvite {
      color: $partnerInviteWaiting !important;

      &:hover {
        color: lighten($partnerInviteWaiting, 10%) !important;
      }
    }

    &.invited, &.invitedToMcn {
      color: $partnerInviteInvited !important;

      &:hover {
        color: lighten($partnerInviteInvited, 10%) !important;
      }
    }

    &.accepted, &.linkedToMcn {
      color: $partnerInviteLinked !important;

      &:hover {
        color: lighten($partnerInviteLinked, 10%) !important;
      }
    }

    &.rejected {
      color: $partnerInviteRejected !important;

      &:hover {
        color: lighten($partnerInviteRejected, 10%) !important;
      }
    }
  }
}

.btn.partner-invite-status {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  color: white;

  &.actionRequired, &.readyForMcnInvite {
    background-color: $partnerInviteReady;

    &.toggled {
      box-shadow: 0 .125rem 1rem rgba(#e67e22, .5);
    }
  }

  &.waitingForInvite {
    background-color: $partnerInviteWaiting;

    &.toggled {
      box-shadow: 0 .125rem 1rem rgba(#f1c40f, .5);
    }
  }

  &.invited, &.invitedToMcn {
    background-color: $partnerInviteInvited;

    &.toggled {
      box-shadow: 0 .125rem 1rem rgba(#3498db, .5);
    }
  }

  &.accepted, &.linkedToMcn {
    background-color: $partnerInviteLinked;

    &.toggled {
      box-shadow: 0 .125rem 1rem rgba(#2ecc71, .5);
    }
  }

  &.rejected {
    background-color: $partnerInviteRejected;

    &.toggled {
      box-shadow: 0 .125rem 1rem rgba(#e74c3c, .5);
    }
  }
}

.filter-buttons {
  button {
    background-color: $btnSecondaryBg;
    font-family: 'Work Sans', Helvetica, sans-serif;
    text-transform: capitalize;
    font-size: .75rem;
    margin: 0 .125rem .5rem;
    opacity: .45;
    box-shadow: none;
    transform: translateY(0);
    text-shadow: 0 1px #00000038;

    &:last-of-type {
      margin-right: .75rem;
    }

    &:focus {
      color: white;
    }

    &:hover,
    &.toggled {
      opacity: 1;
      color: white;
    }

    &.toggled {
      transform: translateY(-.125rem) !important;
    }
  }

  #filter-clear {
    position: relative;
    top: 0;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 26px;
    font-size: .75rem;
    cursor: pointer;
    pointer-events: none;
    transition: all 250ms ease;

    .ion-ios-close-circle-outline {
      font-size: 1.25rem;
      margin-right: .25rem;
    }

    &.enabled {
      color: white;
      pointer-events: auto;
    }
  }
}
