.flatpickr-calendar {
  border-radius: 2px;
  box-shadow: none;
  .flatpickr-innerContainer {
    padding: .5rem 0;
  }
}

.flatpickr-day {
  font-weight: 500;
  font-size: large;
  margin-bottom: 0.25rem;
  border-width: 0;
  line-height: 30px;
  height: 30px;
  border-radius: 0.25rem;
  &.disabled, &.disabled:hover {
    color: rgba(57, 57, 57, 0.11);
  }
  &:hover {
    font-weight: 600;
  }
  &.selected {
    background: #ff990d;
    &:hover {
      background: #ffae32;
    }
  }
  &.today:not(.selected) {
    background: #e7e9ec;
    &:hover {
      background: #d7d9dc;
      color: black;
    }
  }
}

.flatpickr-months {
  //padding-bottom: 0.5rem;
  //background: #5e87dc;
  //.flatpickr-month {
  //  color: white;
  //}
  //.flatpickr-next-month, .flatpickr-prev-month {
  //  color: white;
  //  fill: white;
  //}
}

.flatpickr-current-month {
  span.cur-month {
    font-weight: 400;
    &:hover { // I don't know why they decided to make the month look clickable, it ain't.
      background: none;
    }
  }
  input.cur-year {
    font-weight: 500;
  }
}