.modal {
  background-color: rgba(0, 0, 0, 0.7);
  h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }
  p {
    color: $bodyText;
  }
  &-footer, &-header {
    border-color: lighten($bgColor2, 6%);
  }
  &-footer {
    justify-content: flex-start;
  }
  .modal-dialog {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    .modal-content {
      background-color: $bgColor2;
      .modal-header {
        button {
          color: #FFF;
          cursor: pointer;
        }
      }
      .modal-body {
        overflow: auto;
      }
      .alert {
        &-warning,
        &-info,
        &-success {
          background-color: transparent;
          border: none;
          padding: .5rem;
        }
      }
    }
  }
}