@import "fontfaces";
@import "mixins";
@import "variables";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Dependencies
@import "../../icons/flag-icon-css/flag-icon.min.css";
@import "~ionicons/dist/css/ionicons.css";
@import "~flatpickr/dist/flatpickr.min.css";
@import "~chosen-js/chosen.min.css";
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '../theme/scss/icons/material-design-iconic-font/css/materialdesignicons.min.css';
// Partials
@import "partials/buttons";
@import "partials/dropdowns";
@import "partials/videoGrid";
@import "partials/pills";
@import "partials/errorPage";
@import 'partials/alerts';
@import 'partials/checkboxes';
@import 'partials/swal';
// Components
@import "components/topbar";
@import "components/sidebar";
@import "components/cards";
@import "components/modals";
@import "components/pagination";
@import "components/html5_editor";
@import "components/breadcrumbs";
@import "components/datepicker";
@import "components/chosen";
@import "components/audits";
@import "components/v-tooltip";

.menu-button {
  background: none;
  color: $btnPrimaryBg;

  &:hover {
    color: white;
  }
}

.potential-partner {
  border: 1px solid #00000052;
  text-shadow: 1px 1px #00000063;
  background-color: #4274e2;
  color: #fffffff0;
  font-weight: 300;
  padding: 0.3rem 0.25rem;
  font-size: x-small;
  display: inline-flex;
  align-items: center;
  margin-left: .5rem;
  border-radius: 2px;
}

.partner-invite {
  & > .partner-invite-status {
    height: .5rem;
    opacity: 0.33;
    transition: all .25s ease;
  }

  &:hover {
    & > .partner-invite-status {
      opacity: 1;
    }
  }
}

// todo: remove when bootstrapped
.btn-group {
  .btn {
    margin-right: 0;
  }
}

.input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file {
  width: 1% !important;
}

select[size] {
  padding: 0;

  option {
    &[data-current] {
      background-color: #060606;
    }

    padding: .25rem .5rem;
  }
}

.smart-card {
  > a {
    > .icon-wrapper {
      display: none;
    }

    > .content {
      white-space: nowrap;
      padding: 1rem 2rem;
      flex-grow: 1;
    }

  }

  > a[href] {
    display: flex;
    transition: background-color .25s ease;

    > .icon-wrapper {
      width: 0;
      height: 100%;
      transition: width .5s ease;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $btnPrimaryBg;
      color: $btnSecondaryBg;

      > span {
        font-size: 1.5rem;
      }
    }

    &:hover, &:active, &:focus {
      background-color: $btnSecondaryBg;
      text-decoration: none;

      > .content {
        > h6 {
          color: $btnPrimaryBg !important;
        }
      }

      > .icon-wrapper {
        width: 3.5rem;
      }
    }

    &:focus {
      background-color: inherit;

      > .icon-wrapper {
        width: 3rem;
      }
    }
  }
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

html, body {
  height: 100%;
  padding: 0;
  overflow: hidden;
  background-color: $bgColor;

  //Scrollbar
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-track {
    border-radius: .5rem;
  }

  ::-webkit-scrollbar-corner {
    background: lighten($bgColor2, 12%);
  }

  ::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
    background-color: lighten($bgColor2, 12%);
  }

  ::-webkit-scrollbar-thumb {
    background-color: lighten($bgColor2, 24%);
  }

  ::-webkit-scrollbar-track {
    background-color: lighten($bgColor2, 12%);
  }

}

.card-footer, .card-header {
  padding: 1.25rem;
  background: inherit;
}

.card-header {
  box-shadow: 0 2px rgba(69, 69, 69, 0.07);
}

.card-header > .card-title {
  margin: 0;
}

.form-control, .form-group input, .form-group textarea, .form-group select, .chosen-container-multi .chosen-choices {
  &:disabled {
    background: #23262d;
    border-color: transparent;
    color: #808391;

    &:hover {
      border-color: transparent;
    }
  }

  &:hover {
    border-color: #6a6a6a7d;
  }
}

[data-toggle="collapse"] {
  .card-title .ion {
    &:before {
      content: "\f280";
    }
  }

  &.collapsed {
    .card-title .ion {
      &:before {
        content: "\F284";
      }
    }
  }
}

.icon {
  &-fw {
    width: 1rem;
    text-align: center;
  }

  &-tooltip {
    border: 1px solid !important;
    border-radius: 3px;
    padding: 3px 5px;

    &:hover {
      color: white !important;
    }
  }
}

.card {
  &.card-highlight {
    border: 2px solid $btnPrimaryBg !important;
  }
}

.validateUserModal {
  background-color: rgba(0, 0, 0, 0.7);
}

.form-group.adminSelect {
  input[type="checkbox"] {
    width: 20px !important;
    height: 15px !important;
  }

  label {
    width: calc(100% - 30px);
  }
}

#main-wrapper {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  overflow-x: hidden;
}

body.sidebar-expanded {
  .page-wrapper {
    transform: translateX($sidebarWidthExpandedMobile);
  }
}

body.profileMenu-expanded {
  .page-content {
    transform: translateX(calc(-100% + #{$sidebarWidthMinified}));
  }
}

body.notificationDropdown-expanded {
  .page-content {
    transform: translateX(calc(-100% + #{$sidebarWidthMinified}));
  }
}

//Dashboard content
.page-wrapper {
  transform: translateX(0);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  transition: all 300ms ease;

  .page-content {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 2rem 1rem;
    transition: all 300ms ease;
  }

  .shopPurchaseButton {
    width: 100%;
    background-color: $brandColor;

    &[disabled="disabled"] {
      background-color: $bodyText;
      opacity: 0.5;
    }
  }

  .checkbox {
    &.checkbox-primary {
      input {
        width: unset !important;
        height: unset !important;
      }
    }
  }

  .birthdate {
    width: 100%;
    position: relative;

    select {
      margin-right: 5px;
      margin-bottom: 10px;
      display: inline-block;
    }
  }

  .little-profile {
    .row {
      border-top: 1px solid #2A3335;
      padding-top: 20px;
      margin-top: 30px;

      .col-lg-3 {
        border-left: 1px solid #2A3335;

        &:first-child {
          border: none;
        }
      }
    }
  }

  .pro-img {
    margin-top: -80px;
    margin-bottom: 20px;

    img {
      width: 128px;
      height: 128px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      background: white;
    }

    &--small {
      margin: 0;

      img {
        width: 45px;
        margin-right: 10px;
        display: inline-block;
        height: 45px;
      }
    }
  }

  .little-profile {
    small {
      color: $bodyText;
    }
  }

  .title-container {
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    margin-bottom: 25px;
    border-bottom: 1px solid #3D414F;

    h1 {
      font-family: 'Work Sans', Helvetica, sans-serif;
      font-size: 1.7em;

      small {
        color: $bodyText;
        font-size: 16px;
      }
    }
  }

  .dt-buttons {
    width: fit-content;
    float: left;
  }

  .dataTables_info {
    display: inline-block;
    font-family: 'Work Sans', Helvetica, sans-serif;
    font-size: 13px;
  }

  .dataTables_filter {
    width: fit-content;
    float: right;

    label {
      color: white;
      font-family: 'Work Sans', Helvetica, sans-serif;
      font-size: 12px;
    }

    input {
      margin-left: 10px;
      background-color: $bgColor;
      border: none;
      margin-top: -2px;
      border-bottom: 2px solid $bgColor;
      color: white;
      font-family: 'Work Sans', Helvetica, sans-serif;
      font-size: 13px;
      height: 26px;

      &:focus {
        border-bottom: 2px solid $brandColor;
        outline: none;
      }

      &:active {
        border-bottom: 2px solid $brandColor;
        outline: none;
      }
    }
  }

  .table {
    .btn {
      margin: 0 0 .25rem .25rem;

      &:only-child {
        margin: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    border: none;

    tfoot {
      display: none;
      @media screen and (max-height: 600px) {
        display: table-footer-group;
      }
    }

    th {
      color: white;
      font-family: 'Work Sans', Helvetica, sans-serif;
      font-weight: 400;
      background-color: lighten($bgColor2, 3%);
      font-size: 13px;
      white-space: nowrap;
      vertical-align: middle;

      &.actions {
        width: 1px !important;
      }
    }

    tr {
      td {
        color: $bodyText;
        font-size: 12px;
        vertical-align: middle;

        &.actions, &.currency {
          white-space: nowrap;
        }

        a {
          //color: $brandColor;

          &.btn {
            //background-color: $bgColor !important;
            //font-family: 'Work Sans', Helvetica, sans-serif !important;
            font-size: 11px !important;
            color: white !important;

            &:hover {
              //background-color: $brandColor !important;
              transition-duration: 0.2s !important;
              cursor: pointer !important;
            }
          }
        }

        form {
          .form-control:focus {
            border: none;
          }

          select {
            font-size: 13px;
            padding: .25rem 0 .25rem .25rem;
            height: auto !important;

            option {
              padding: .25rem 0;
            }
          }

          button {
            font-size: 12px;
            padding: .25rem;
            margin: 0 !important;
          }
        }
      }
    }

    * {
      border: none;
    }

  }
}

// BEGIN: Video list pagination
.video-list-paginator {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  margin-bottom: $bottomPaddingSmartPhones;
  @media screen and (min-width: 30rem) {
    margin-bottom: 2rem;
  }

  .pagination {
    margin: 0;
    background-color: $bgColor2;

    .paginate_button {
      color: $bodyText !important;
      background-color: transparent !important;
      transition: none;
      font-size: 1rem !important;

      &.current,
      &.disabled {
        pointer-events: none;
      }

      &.current {
        color: $brandColor !important;
        background-color: lighten($bgColor2, 9%) !important;
      }

      &.previous,
      &.next {
        &.disabled {
          display: none;
        }
      }

      &:hover {
        color: #fff !important;
        background-color: lighten($bgColor2, 9%) !important;
      }
    }
  }
}

// END: Video list pagination

//BEGIN: Typography
body {
  font-family: 'Work Sans', Helvetica, sans-serif;
  font-weight: 400;
  color: $bodyText;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Barlow Condensed', Helvetica, sans-serif;
  color: white;
}

a {
  color: $brandColor;

  &:hover {
    color: darken($brandColor, 10%);
    text-decoration: none;
  }
}

.ver-align {
  display: flex;
  align-items: center;
}

.card-body-title {
  margin-top: .75rem;
  font-family: 'Work Sans', Helvetica, sans-serif;
  font-size: 16px;
}

// END: Typography

//Login & Register
.full-page-center {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;

  .container {
    max-width: 1000px;

    .right-side-auth {
      width: 55%;
      min-height: 600px;
      padding: 30px;
      height: auto;
      overflow: hidden;
      float: left;
      background: $bgColor2;
      position: relative;
      z-index: 2;
      box-shadow: -20px 2px 64px 0 rgba(0, 0, 0, 0.09);

      .logo {
        width: 200px;
        margin-bottom: 10px;
      }

      form {
        margin-top: 30px;
      }

      .form-group {
        margin-top: 10px;

        &.has-danger {
          label {
            color: #e74c3c !important;

            a {
              color: #c0392b !important;
            }
          }

          input {
            border-left: 3px solid #e74c3c;
          }
        }

        .btn-spex {
          width: 80%;
          margin-top: 30px;
        }

        .checkbox {

          &:hover {
            cursor: pointer;
          }

          input {
            position: absolute;
            margin-top: 5px;

            &:hover {
              cursor: pointer;
            }
          }

          label {
            color: $bodyText;
            font-family: 'Barlow Condensed', Helvetica, sans-serif;
            margin-left: 24px;

            &:hover {
              cursor: pointer;
            }
          }
        }

        .reset-password {
          font-family: 'Barlow Condensed', Helvetica, sans-serif;
          transition-duration: 0.3s;
          color: $bodyText;

          &:hover {
            color: $brandColor;
            transition-duration: 0.3s;
            text-decoration: none;
          }
        }

        &.no-account {
          font-family: 'Barlow Condensed', Helvetica, sans-serif;
          color: $bodyText;

          a {
            color: $brandColor;
          }
        }
      }

      .input-wrap {
        width: 100%;
        text-align: left;

        label {
          color: #eee;
          font-family: 'Barlow Condensed', Helvetica, sans-serif;
        }

        input {
          width: 100%;
          height: 54px;
          border: none;
          padding-left: 15px;
          padding-right: 15px;
          transition-duration: 0.3s;
          background: #131419;
          border-radius: .25rem;
          font-family: 'Work Sans', Helvetica, sans-serif;
          font-size: 14px;
          color: #FFF;
          letter-spacing: 0.23px;
          line-height: 25.2px;

          &:active {
            box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
            transform: scale(1.02);
            outline: none;
            transition-duration: 0.2s;
          }

          &:focus {
            box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
            transform: scale(1.02);
            outline: none;
            transition-duration: 0.2s;
          }
        }
      }
    }

    .left-side-auth {
      position: relative;
      width: 45%;
      z-index: 1;
      min-height: 400px;
      background: $bgColor2;
      float: left;
      padding: 30px;

      h1 {
        font-weight: 500;
        font-size: 70px;
      }

      p {
        opacity: 0.61;
        font-family: 'Work Sans', Helvetica, sans-serif;
        font-size: 18.48px;
        color: #FFFFFF;
        letter-spacing: 0.45px;
        margin-top: 20px;
        line-height: 30px;
        font-weight: 300;
      }
    }
  }
}

label[for="description"] {
  textarea {
    font-size: 13px;
    color: #FFF;

    &:focus {
      color: #FFF;
    }
  }

}

.termsModal {
  .modal-header {
    border-bottom: 1px solid #121B1D;
  }

  .modal-footer {
    border-top: 1px solid #121B1D;
  }

  .modal-title {
    font-family: 'Barlow Condensed', Helvetica, sans-serif;
  }

  .modal-content {
    background-color: #1B2428;
  }

  .modal-body {
    max-height: calc(100vh - 400px);
    overflow: scroll;

    p {
      font-family: 'Work Sans', Helvetica, sans-serif;
      color: $bodyText;
      font-size: 13px;

      &.contract-text, &.contract-text div {
        color: $contractText;
      }
    }

    div.contract-text {
      font-family: 'Work Sans', Helvetica, sans-serif;
      font-size: 13px;
      color: $contractText;
    }
  }
}

// MEDIA QUERIES
@media screen and (max-width: 900px) {
  .left-side-auth {
    display: none;
  }
  .full-page-center {
    display: block;
    overflow: scroll;
  }
  .right-side-auth {
    width: 100% !important;
    max-width: 480px;
    padding: 0px !important;
    padding-top: 30px !important;
    margin-top: 10px;
    margin-bottom: 100px;
    float: unset !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.collapse.show {
  visibility: visible;
}

.bootstrap-select .dropdown-menu li a {
  display: block;
  padding: 7px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #99abb4;
  white-space: nowrap;
}

.dropdown-menu {
  background-color: #212529;

  &.inner {
    display: block;
  }
}

a[aria-selected=true] {
  background-color: $brandColor;
}

.btn-group.bootstrap-select {
  width: 100% !important;

  .btn.dropdown-toggle {
    background-color: $bgColor;
    font-family: 'Work Sans', Helvetica, sans-serif;
    color: #fff;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    transition-duration: 0.2s;
    border: 1px solid $bgColor;
    border-radius: 5px;
    padding: 0px;
    padding-left: 15px;
    text-transform: capitalize;

    &:active {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    &::after {
      display: none !important;
    }
  }
}

.form-control {
  background-color: $bgColor;

  &:focus {
    background-color: $bgColor;
    border: 1px solid $brandColor;
  }
}

textarea {
  &.form-control {
    color: #6b757e;

    &:focus {
      color: #d7963d;
    }
  }
}

// Connect youtube channel
.youtube-connect {
  .logo {
    display: block;
    margin: 0 auto;
    width: 200px;
    padding-bottom: 40px;
  }

  .log-out {
    position: absolute;
    top: .5rem;
    left: .5rem;
    right: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      color: $bodyText;

      &:hover {
        color: rgba(255, 255, 255, .8);
      }
    }
  }

  .content {
    #button {
      margin-top: 40px;
    }
  }
}

.translations {
  em {
    font-size: larger;
    opacity: .3;
    margin: 0 1px;

    &.completed {
      opacity: 1;
    }
  }
}

.memo-channel {
  display: inline-block;
  color: #ffffff;
  padding: 0.25rem 0.8rem;
  margin-right: .25rem;
  background: #48538a;
  font-weight: 500;
  border-radius: 4rem;

  &-broadcast {
    background: #cc6709;
  }
}

.translations {

  &.translation-status {
    &-completed {
      &, div, td {
        color: #70d378 !important;
      }
    }

    &-in-progress {
      &, div, td {
        color: #8a8ad3 !important;
      }
    }
  }

  div, td {
    padding: 0 .75rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &.segments {
      text-align: right;
      font-family: monospace;
    }

    &.completion {
      text-align: right;
    }
  }

}

.memo-status {
  display: inline-block;
  background: #25262d;
  color: rgba(255, 255, 255, 0.5);
  border: 2px solid transparent !important;
  padding: .2rem .75rem;
  width: 100%;
  white-space: nowrap;
  border-radius: .25rem;

  &-scheduled {
    color: #7eba7e;
    border-color: #689d68 !important
  }

  &-localization, &-in-progress {
    color: #9099da;
    border-color: #6974c0 !important;
  }

  &-published, &-completed {
    background: #689d68;
    color: #ffffff;
  }

  &-unread {
    background: #df8221;
    color: #ffffff;
  }
}

// Badges
.user-role-badge {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: .125rem .5rem;
  margin: 0 .25rem .25rem 0;
  border-radius: 2px 8px 2px 8px;
  color: #fff;
  white-space: nowrap;
  font-weight: 500;

  &:only-of-type {
    margin: 0;
  }

  &-super-admin,
  &-admin {
    &::before {
      content: '';
      display: inline-block;
      width: .75rem;
      height: .75rem;
      background: url('~@/images/backend/icon_admin-star.svg') center no-repeat;
      margin-right: .25rem;
    }
  }

  &-referrer {
    background: #b64800;
  }

  &-super-admin {
    color: lighten($badgeSuper, 50%);
    background-color: $badgeSuper;
  }


  &-admin {
    color: lighten($badgeAdmin, 50%);
    background-color: $badgeAdmin;
  }

  &-community-manager {
    background-color: $badgeCommunityManager;
  }

  &-department-manager {
    background-color: $badgeDepartmentManager;
  }

  &-department-owner {
    color: lighten($badgeDepartmentOwner, 50%);
    background-color: $badgeDepartmentOwner;
  }

  &-partner-manager {
    background-color: $badgePartnerManager;
  }

  &-partner {
    background-color: $badgePartner;
  }

  &-potential-partner {
    background-color: $badgePotentialPartner;
  }

  &-recruiter {
    background-color: $badgeRecruiter;
  }

  &-member {
    color: darken($badgeMember, 70%);
    background-color: $badgeMember;
  }

  &-rejected {
    background-color: $badgeRejected;
  }
}

.table-responsive {
  overflow-x: auto;
}

// partner invitation
.invite-channel-search {
  #youtube_channel_id,
  #email,
  #revenue {
    min-width: 260px;
  }

  .input-group {
    button[type="submit"] {
      border-radius: 0;
      height: 95%;
      margin-top: 1px;
    }

    @media screen and (max-width: 470px) {
      flex-direction: column;
      input[type="text"] {
        width: 100%;
      }
      button[type="submit"] {
        border-radius: .25rem;
        margin-top: 5px;
      }
    }
  }
}

//search
#partnerInviteTable_filter,
#userListTable_filter,
#collaborationListTable_filter,
#potentialListTable_filter {
  min-width: 420px;
  padding-right: 10px;

  label {
    float: right;
    width: 100%;

    input {
      width: 100%;
      height: 35px;
      padding-left: 10px;
      border: .5px solid rgba(68, 68, 94, 0.36);

      &:focus {
        border-bottom: 2px solid $brandColor;
      }
    }
  }

  @media screen and (max-width: 1235px) {
    float: none;
    width: 420px;
    label {
      margin: .5rem 0;

      input {
        margin: 0;

        &::placeholder {
          font-style: italic;
        }
      }
    }
  }
}

//hover message widget
.message-widget {
  a {
    &:hover {
      text-decoration: none;
    }

    &:last-child {
      .mail-contnet {
        border-bottom: none;
      }
    }
  }
}

//revenue import
.card-body {
  .form-group {
    input[type="file"] {
      line-height: 17px;
      padding-top: 8px;
    }
  }
}

//payableRevenues info
.payment-accordion .card {
  margin-bottom: 0;

  & .card-header {
    padding: 0.25rem;
  }
}

// Create recruiter
.desc-text {
  display: block;
  font-size: 12px;
  color: $bodyText;

  &-small {
    @extend .desc-text;
    font-size: 10px;
    letter-spacing: 0.5px;
    margin-top: 0.25rem;
    line-height: 1.2;
  }
}

// Toasts
.jq-toast-wrap {
  overflow: hidden;
  border-radius: .25rem;

  .jq-toast-single {
    margin: 0;
  }

  &.top-right {
    top: calc(#{$topbarHeight} + 1rem);
    right: 30px;
  }
}

// Settings
.settings-content {
  .birthdate {
    .form-control {
      &:first-child {
        max-width: 90px;
      }

      max-width: 70px;
    }
  }

  .parentalAcceptance {
    em {
      font-size: 13px;
    }
  }

  .validation-content {
    margin-top: 1.5rem;
  }

  .payment-settings {
    .payment-options {
      input {
        height: auto;
        width: auto;
        display: inline-block;
        margin-top: .4rem;
        margin-right: .75rem;
      }
    }

    fieldset[disabled = "disabled"] {
      input {
        &:active,
        &:focus {
          border: none;
        }
      }
    }
  }

  .locale-selection {
    list-style: none;

    a {
      padding-left: 0;
    }

    .dropdown-menu {
      background-color: #25262d;

      a {
        padding: .5rem 0 .5rem 1rem;
        color: #808391;

        &:hover {
          color: #ff990d;
          background-color: #2c2d36;
        }
      }
    }
  }
}

// Error-messages
.form-control-feedback {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 2.25rem;
  margin-bottom: .5rem;
  width: max-content;
  padding: 0 .75rem;
  color: $errorDanger;
  border: 1px solid rgba($errorDanger, .25);
  font-size: 14px;
  font-style: italic;
  border-radius: .25rem;

  i {
    font-size: 21px;
    margin-right: .5rem;
  }
}

// Tooltip icons
i {
  &.icon {
    position: relative;
  }

  &.icon.custom-coin {
    display: inline-flex;
    align-items: center;

    &::before {
      content: '';
      display: inline-block;
      position: relative;
      bottom: -1px;
      width: .875rem;
      height: .875rem;
      background: url("~@/images/backend/icon_coin.svg") center no-repeat;
    }
  }
}

.stat-tag {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .tooltip-icon {
    top: 25px;
  }
}

.tooltip-icon {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-top: 5px;
  background-color: lighten($bgColor2, 10%);
  color: #FFF;
  font-style: normal;
  padding: 5px 10px;
  border-radius: .25rem;
  z-index: 1;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

//Create new Term
.create-term {
  .form-group {
    box-shadow: none !important;
  }
}

.modal-backdrop {
  display: none !important;
}

//Shop
.shop {
  h4 {
    font-size: 18px;
  }

  .card-text {
    display: flex;
    flex-direction: row;

    svg {
      width: 1rem;
      height: 1rem;
      margin-left: .25rem;
    }

    svg path {
      fill: #ff990d;
    }
  }

  &.card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    @media screen and (min-width: 30rem) {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
    @media screen and (min-width: 50rem) {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
    @media screen and (min-width: 70rem) {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
    }
  }
}

.module-subscriptions {
  white-space: nowrap;

  .module-subscription {
    display: inline-block;
    background: #353b48;
    border-radius: 2px;
    padding: 0.3rem 0.4rem;
    line-height: 0;
    color: #f79511;
    font-size: 1rem;
    margin-right: 0.2rem;
    transition: transform .25s ease;

    .icon {
      width: .9rem;
      display: inline-block;
      text-align: center;
    }

    &:hover {
      transform: translateY(-2px);
    }
  }

}

.dt-buttons {
  display: flex;
}

.graph-flex {
  flex-grow: 1;
  margin-bottom: 2rem;

  .card {
    height: 100%;
    margin-bottom: 0;
  }
}

.graph-box-flex {
  flex-grow: 1;
  justify-content: center;
  padding: 20px;

  &-h6 {
    color: rgb(128, 131, 145);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.07rem;
  }
}

.graph-box-flex-border {
  border: 2px solid;
}

.card-referral {
  .card-header {
    .card-title {
      color: $bodyText;
      margin-bottom: 0;
      font-weight: 600;
      letter-spacing: 0.05rem;
    }
  }
}

html.websockets-dashboard {
  overflow: visible;

  body {
    overflow: visible;

    #main-wrapper {
      display: block;
      //flex-wrap: nowrap;
      height: auto;
      overflow-x: visible;

      .card {
        background-color: #ffffff;
        color: #000000;

        h1, h2, h3, h4, h6 {
          color: #000000;
        }

        .form-control {
          background-color: #ffffff;
        }
      }
    }
  }
}

.deactivated {
  color: #ff430b !important;
}

span {
  &.highlight-orange {
    color: rgb(255, 153, 13);
  }

  &.highlight-light-green {
    color: rgba(214, 251, 211, 0.81);
  }

  &.highlight-green {
    color: rgba(162, 251, 155, 0.81);
  }

  &.highlight-light-purple {
    color: rgba(232, 172, 255, 0.77);
  }

  &.highlight-white {
    color: rgba(255, 255, 243, 0.71);
  }
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1, .flex-grow {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.revenueRow {
  &.hasDetails {
    cursor: pointer;
  }
}

@import "media_queries";
