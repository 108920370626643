.chosen-container-multi .chosen-choices li.search-field input[type=text] {
  color: #808391;
  font-family: inherit;
  padding-left: 12px;
}

.chosen-container-multi {
  .chosen-choices {
    color: gray;
    background: #131419 none;
    font-family: "Work Sans", Helvetica, sans-serif;
    color: white;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    transition-duration: 0.2s;
    border: 1px solid #131419;
    border-radius: 5px;
    padding: 0 4px;
    .search-choice {
      padding: 4px 30px 4px 12px !important;
      background: #1e1f25 !important;
      box-shadow: none !important;
      border: none !important;
      color: white !important;
      font-weight: 300;
      line-height: 24px !important;
      a.search-choice-close {
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
      }
    }
  }

  &.chosen-with-drop {
    .chosen-choices {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .chosen-drop {
    border: none;
    background: #131419;
    li {
      line-height: 22px;
      border-radius: .15rem;
      &.result-selected {
        color: inherit;
      }
      &.active-result {
        color: #d1d1d1;
        &.highlighted {
          background: #ff990d none;
          color: #000;
        }
      }
    }
  }
}
