$audit-modal-bg:  #1e1f25;
$audit-list-bg:  #19191e;
$audit-row-bg:  #1d1f26;
$audit-row-bg-hover: #25262d;
$audit-key-border-bottom:  #404040;
$audit-key-color: #787878;
$audit-key-active: darkgoldenrod;
$model-bg:  #b88615;
$model-user-bg:  #b88615;
$model-system-bg:  #b81579;
$audit-highlight: goldenrod;
$audit-highlight-dark: darkgoldenrod;
$audit-highlight-bright: white;

.audit-card {
  overflow: hidden;
  position: relative;
  min-height: 30rem;
  height: 100%;

  .dev-sql {
    font-size: x-small;
    user-select: all;
    background: $audit-row-bg-hover;
  }

  .dev-palette-viewer {
    display: flex;
    flex-wrap: wrap;
    .color {
      user-select: all;
      min-width: 5rem;
      width: 14%;
      text-align: center;
      flex-grow: 1;
      color: #000000cc;
    }
  }

  button.search-clear {
    color: $audit-highlight-dark;
    background: none;
    outline: 0;
    border: 0;
    cursor: pointer;
    display: inline-flex;

    &:hover {
      color: $audit-highlight;
    }
  }

  .card-body {
    font-size: small;
  }

  .search-bar {
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    flex-grow: 1;
    justify-content: flex-start;
    padding: 5px 8px;
    white-space: nowrap;
    &::-webkit-scrollbar {
      height: 0.25rem;
    }
    .search-terms {
      &:hover {
        color: $audit-highlight;
        cursor: pointer;
      }
    }
    .search-item {
      &.active {
        border-style: solid;
      }

      color: $audit-highlight-dark;
      border: 1px dashed;
      justify-content: start;
      align-items: center;
      padding: 0 10px;
      font-size: 12px;
      margin-right: 8px;
      transition: color .5s ease;
      line-height: 1.5rem;
      > span {
        text-transform: capitalize;
      }
      .search-remove {
        cursor: pointer;
        &:hover {
          color: $audit-highlight-bright;
        }
      }
      .search-value {
        font-weight: 500;
      }
    }
  }

  .pagination > .page-item.disabled > a.page-link, .pagination > .page-item > a.page-link {
    background: transparent;
    border: 1px solid transparent;
  }

  .pagination {
    font-size: small;
    margin-bottom: 0;
    & > .page-item {
      &.active > a.page-link {
        background: transparent;
        border: 1px solid $audit-highlight-dark;
        color: $audit-highlight-dark;
      }
      & > a.page-link {
        color: $audit-highlight-dark;
        &:hover {
          color: $audit-highlight;
        }
      }
    }
  }

  a.page-link:active,
  a.page-link:focus,
  a.page-link:focus:active {
    background-image: none;
    outline: 0;
    box-shadow: none;
  }
  .actions {
    & > button, & .dropdown > .dropdown-toggle {
      margin: 0;
      border-radius: 0;
      transform: translateY(0);
      min-width: 2.25rem;
      &::after {
        border: 0;
        margin: 0;
        padding: 0;
        display: none;
      }
    }
  }

  tr {
    &.change-row {
      td {
        padding: 0;
      }
    }
    &.audit-row {
      background: $audit-row-bg;
      &:hover {
        background: $audit-row-bg-hover;
      }
    }
  }

  tr.audit-row .user, tr.audit-row .subject {
    font-weight: 500;
    font-family: monospace;
    white-space: nowrap;
    text-transform: capitalize;
  }

  .model {
    background: $model-bg;
    color: black;
    font-family: monospace;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    width: 8rem;
    display: inline-flex;
    text-transform: capitalize;
    flex-wrap: wrap;

    > div {
      padding: .2rem 0.4rem;
    }

    .info {
      width: 100%;
      display: flex;
    }
    .type, .name {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .name {
      font-style: italic;
      text-align: center;
      background: #ffffff1f;
    }
    .id {
      flex-grow: 1;
      text-align: right;
      font-weight: 600;
      padding-left: .5rem;
    }
    &.user {
      background: $model-user-bg;
    }
    &.system {
      display: inline-block;
      text-align: center;
      background: $model-system-bg;
      color: white;
      .type {
        text-align: center;
      }
    }
  }

  ul {
    &.audit-keys {
      margin-bottom: 0;
      padding: 0;
      li {
        list-style: none;
        padding: .5rem 0;
        padding-left: .75rem;
        border-bottom: 1px dashed $audit-key-border-bottom;
        transition: border-left .25s ease;
        border-left: 2px solid transparent;
        color: $audit-key-color;
        &:last-child {
          border-bottom: none !important;
        }
        &.active {
          border-left: 2px solid $audit-key-active;
        }
      }
    }
  }

  div {
    &.selectable {
      cursor: pointer;
      &:hover {
        color: $audit-highlight;
      }
    }
    &.selected {
      animation-name: highlightText;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      color: $audit-highlight;
    }
  }

  .scroll-indicator {
    position: absolute;
    bottom: 0;
    background: linear-gradient(0, #00000047, transparent);
    text-align: center;
    left: 0;
    width: 100%;
    padding: 0.5rem;
    pointer-events: none;
    z-index: 10;
    opacity: 0;
  }
  .scroll-indicator.active {
    opacity: 1;
  }

  .scroll-indicator .icon {
    transform: translateY(0);
    animation-name: highlightScroll;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  @keyframes highlightScroll {
    50% {
      transform: translateY(5px);
    }
  }

  @keyframes highlightText {
    50% {
      color: $audit-highlight-dark;
    }
  }

  .value, .key {
    font-weight: 500;
    color: $audit-highlight-dark;
    font-family: monospace;
  }

  .key {
    cursor: pointer;
    &:hover {
      color: $audit-highlight;
    }
  }

  .audit-list-container {
    position: relative;
    flex-grow: 1;
    background: $audit-list-bg;
    .audit-list {
      position: relative;
      overflow-y: auto;
      min-height: 100%;
      max-height: 38rem;

      & > table {
        margin-bottom: 0;
      }
    }
  }

  .loading-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(19, 20, 25, 0.51);
    z-index: 100;
    & > .icon-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.modal-mask {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  &.top-right {
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.modal-wrapper {
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: $audit-modal-bg;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}