// Theme
$bodyText: #808391;
$contractText: #8f919d;
$bgColor: #131419;
$bgColor2: #1e1f25;
$brandColor: #ff990d;
$errorDanger: #e84118;
$alertSuccess: #4cd137;

// Bootstrap
$input-border-focus: $brandColor;
$input-group-addon-border-color: #131419;
$dropdown-link-color: #808391;
$dropdown-link-hover-color: #ff990d;
$dropdown-bg: #292b33;
$dropdown-link-hover-bg: #2c2d36;
$dropdown-link-disabled-color: #57585a;
$dropdown-link-active-bg: #35404b;
$dropdown-link-active-color: white;
$input-border-color: transparent;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-box-shadow: none;
$enable-caret: true;
$primary: $brandColor;

// Layout
$topbarHeight: 4rem;
$bottomPaddingSmartPhones: 5.5rem;
$sidebarWidthMinified: 4rem;
$sidebarWidthExpandedMobile: calc(100vw - 4rem);
$sidebarWidthExpandedDesktop: 280px;

// Custom breakpoints for bootstrap (adjusted to take our sidebar into account)
$grid-breakpoints: (
        xs: 0,
        sm: 576px + $sidebarWidthExpandedDesktop,
        md: 768px + $sidebarWidthExpandedDesktop,
        lg: 992px + $sidebarWidthExpandedDesktop,
        xl: 1200px + $sidebarWidthExpandedDesktop
);

// Buttons
$btnDefaultBg: $bodyText;
$btnPrimaryBg: $brandColor;
$btnSecondaryBg: lighten($bgColor2, 6%);
$btnSuccessBg: #019c22;
$btnDangerBg: #e84118;


// User role badges
$badgeSuper: #353b48;
$badgeAdmin: #353b48;
$badgeCommunityManager: #00a8ff;
$badgeDepartmentOwner: #628455;
$badgeDepartmentManager: #9c88ff;
$badgePartnerManager: #cd9d2c;
$badgePartner: #40739e;
$badgePotentialPartner: #273c75;
$badgeRecruiter: #44bd32;
$badgeMember: #dcdde1;
$badgeRejected: #e84118;
