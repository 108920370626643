.alert {
  border-color: transparent;
  background-color: lighten($bgColor2, 3%);
  h1, h2, h3, h4, h5, h6 {
    color: #fff !important;
  }
  p {
    color: $bodyText;
    margin: 0;
  }
  &-warning,
  &-info,
  &-success {
    border: none;
    i {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      margin-right: .25rem;
      border-radius: 100rem;
      font-size: 1.5rem;
    }
  }
  &-warning {
    border-left: 0.25rem solid $errorDanger;
    i {
      background-color: $errorDanger;
      &::before {
        margin-top: -.125rem;
      }
    }
  }
  &-info {
    border-left: 0.25rem solid $brandColor;
    i {
      background-color: lighten($bgColor2, 15%);
      font-size: 2rem;
    }
  }
  &-success {
    border-left: 0.25rem solid $alertSuccess;
  }

  &.alert-onpage {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    i.icon {
      flex-shrink: 0;
      margin-right: .5rem;
    }
  }
}