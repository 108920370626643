// BEGIN: Custom pagination
.dataTables_paginate {
  float: right;
  display: flex;
  flex-wrap: nowrap;
  background-color: $bgColor2;
  border-radius: .25rem;
  font-size: .875rem;
  .paginate_button {
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    padding: 0 .75rem;
    color: $bodyText;
    &:hover {
      text-decoration: none;
      cursor: default;
    }
    &.current {
      color: $brandColor;
      background-color: lighten($bgColor2, 3%)
    }
    &.disabled {
      color: rgba($bodyText, .5);
    }
    &.previous {
      &::before {
        content: '\00ab';
        margin-right: .25rem;
      }
    }
    &.next {
      &::after {
        content: '\00bb';
        margin-left: .25rem;
      }
    }
  }
  a.paginate_button:not(.disabled):not(.current) {
    cursor: pointer;
    &:hover {
      color: #fff;
      text-decoration: none;
      background-color: lighten($bgColor2, 3%)
    }
  }
}

.card {
  .dataTables_paginate {
    background-color: $bgColor;
    .paginate_button {
      &.current {
        background-color: lighten($bgColor, 3%)
      }
    }
    a.paginate_button:not(.disabled):not(.current) {
      &:hover {
        background-color: lighten($bgColor, 3%)
      }
    }
  }
}

// END: Custom pagination
