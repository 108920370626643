.pill {
	width: fit-content;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 100px;
	height: fit-content;
	background-color: #34495e;
	color: white;
	font-weight: 500;
	&.positive {
		background-color: #2ecc71;
	}
	&.negative {
		background-color: #e74c3c;
	}
}
