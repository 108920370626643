// BEGIN: Sidebar
#toggleSidebar {
  z-index: 99999;
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  cursor: pointer;
  transform: translateX(0) rotate(0);
  transition: all 300ms ease;
  svg {
    height: 1rem;
    path {
      transition: all 300ms ease;
      fill: $bodyText;
    }
  }
  &:hover {
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

#toggleSidebarDesktop {
  display: none;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  transform: translateX(0) rotate(0);
  transition: all 300ms ease;
  svg {
    margin-top: .5rem;
    height: 1rem;
    path {
      transition: all 300ms ease;
      fill: $bodyText;
    }
  }
    &:hover {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

body.sidebar-expanded {
  #toggleSidebar, #toggleSidebarDesktop {
    transform: rotate(540deg);
  }

}

#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-shrink: 0;
  background-color: $bgColor2;
  border-right: 1px solid darken($bgColor2, 5%);
  overflow: hidden;
  transform: translateX(-$sidebarWidthMinified);
  transition: all 300ms ease;
  z-index: 9999;
  .sidebar-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    padding: 1rem;
    flex-shrink: 0;
    .sidebar-brand {
      display: block;
      visibility: hidden;
      opacity: 0;
      width: 0;
      align-items: center;
      justify-content: center;
      img {
        max-height: 3rem;
        max-width: 100%;
      }
    }
  }
  .sidebar-nav {
    flex-grow: 1;
    padding: 2rem 0;
    overflow-x: hidden;
    overflow-y: auto;
    > a {
      padding: 0 1rem;
      position: relative;
      display: block;
      text-decoration: none;
      .menu-item {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        line-height: 3rem;
        height: 3rem;
        color: $bodyText;
        font-family: 'Work Sans', Helvetica, sans-serif;
        font-weight: 500;
        i.icon {
          width: $sidebarWidthMinified;
          text-align: center;
          font-size: 1.5rem;
          transition: all 300ms ease;
          &.fas, &.far, &.fa {
            font-size: 1.25rem;
          }
        }
        span {
          width: 100%;
          white-space: nowrap;
          i.icon {
            font-size: 1rem;
            vertical-align: middle;
          }
        }
        &.dropdown-toggle {
          &::after {
            content: '';
            position: absolute;
            right: -.5rem;
            top: 50%;
            transform: translateY(-50%);
            transition: all 300ms ease;
          }
        }
      }
      &[aria-expanded="true"] {
        background: lighten($bgColor2, 3%);
        .dropdown-toggle {
          &::after {
            transform: translateY(-50%) rotate(-180deg);
          }
        }
      }
      &:not([data-toggle="collapse"]) {
        &:hover,
        &:focus,
        &.active {
          background: lighten($bgColor2, 3%);
          .menu-item {
            span {
              color: $brandColor;
              i.icon {
                font-size: 1rem;
              }
            }
            i.icon {
              color: $brandColor;
              font-size: 1.875rem;
              &.fas, &.far, &.fa {
                font-size: 1.5rem;
              }
            }
          }
        }
        &.active {
          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: .25rem;
            background-color: $brandColor;
          }
        }
      }
      &[data-toggle="collapse"] {
        &:hover {
          background: lighten($bgColor2, 3%);
          .menu-item {
            span {
              color: $brandColor;
            }
            i.icon {
              color: $brandColor;
              font-size: 1.875rem;
            }
          }
        }
      }
    }
    .menuCollapse {
      overflow-x: hidden;
      a {
        display: block;
        padding: 0 2rem;
        text-decoration: none;
        background: lighten($bgColor2, 3%);
        .menu-item {
          border-left: 1px solid rgba($bodyText, .25);
          padding-left: 1rem;
          color: rgba($bodyText, .75);
          line-height: 2.5rem;
          transition: padding 300ms ease;
          white-space: nowrap;
        }
        &.collapse.in {
          display: block !important;
        }
        &.active,
        &:hover,
        &:focus {
          .menu-item {
            border-color: $brandColor;
            color: $brandColor;
            padding-left: 1.5rem;
          }
        }
        &:first-child {
          padding-top: 1rem;
        }
        &:last-child {
          margin-bottom: 1rem;
          padding-bottom: 1rem;
        }
      }
    }
  }
  .copyright {
    padding: 1rem 1rem $bottomPaddingSmartPhones 1rem;
    flex-shrink: 0;
    @media screen and (min-width:30rem) {
      padding: 2rem 1rem;
    }
    .logo {
      display: none;
      margin: 0 auto 1rem;
      width: 6rem;
    }
    span {
      display: block;
      font-size: .75rem;
      text-align: center;
      color: $bodyText;
      white-space: nowrap;
      transform: rotate(-90deg);
    }
  }
  &.minified {
    width: $sidebarWidthMinified;
    .sidebar-nav {
      > a {
        .menu-item {
          span {
            display: none;
          }
        }
      }
    }
  }
  &.expanded {
    width: $sidebarWidthExpandedMobile;
    transform: translateX(0);
    .sidebar-brand {
      visibility: visible;
      opacity: 1;
      width: 100%;
    }
    .sidebar-nav {
      > a {
        .menu-item {
          span {
            display: block;
          }
          &.dropdown-toggle {
            &::after {
              right: 0;
            }
          }
        }
        &:not([data-toggle="collapse"]) {
          &:hover,
          &:focus,
          &.active {
            .menu-item {
              i.icon {
                margin-right: .5rem;
              }
            }
          }
        }
        &[data-toggle="collapse"] {
          &:hover {
            .menu-item {
              i.icon {
                margin-right: .5rem;
              }
            }
          }
        }
      }
    }
    .copyright {
      .logo {
        display: none;
        @media screen and (min-width:30rem) {
          display: block;
        }
      }
      span {
        transform: rotate(0);
      }
    }
  }
}

// END: Sidebar
