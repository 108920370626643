// BEGIN Media Queries
@media screen and (max-width: 50rem) {
  #profileMenu {
    .dropdown-menu {
      position: fixed;
      top: calc(4rem + 1px);
      right: 0;
      display: flex;
      flex-direction: column;
      padding: 2rem 0;
      opacity: 1;
      visibility: hidden;
      overflow-x: hidden;
      overflow-y: auto;
      border: none;
      border-radius: 0;
      min-width: unset;
      width: $sidebarWidthExpandedMobile;
      height: 100vh;
      transform: translateX($sidebarWidthExpandedMobile);
      background-color: $bgColor2;
      transition: transform 300ms ease;
      .dropdown-item {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        height: 3rem;
        margin-bottom: 1px;
        font-weight: 500;
        color: $bodyText;
        i {
          font-size: 1.5rem;
          margin-right: 1rem;
        }
        &:not(:last-child) {
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: calc(100% - 2rem);
            height: 1px;
            background-color: rgba($bodyText, .125);
          }
        }
      }
    }
    &.show {
      .dropdown-menu {
        visibility: visible;
        transform: translateX(0);
      }
    }
  }
}

@media screen and (min-width: 50rem) {
  .page-wrapper {
    padding-left: 0;
  }
  #toggleSidebar {
    display: none;
  }
  #toggleSidebarDesktop {
    display: inline-block;
  }
  body.sidebar-expanded {
    .page-wrapper {
      transform: translateX(0) !important;
      padding-left: 0;
      transition: all 250ms ease;
    }
  }
  body.sidebar-expanded {
    #toggleSidebar {
      transform: translateX(rotate(180deg));
    }
  }
  body.profileMenu-expanded {
    .page-content {
      transform: translateX(0) !important;
    }
  }
  #sidebar {
    position: relative;
    top: unset;
    right: unset;
    transform: translateX(0);
    &.minified {
      width: $sidebarWidthMinified !important;
    }
    &.expanded {
      width: $sidebarWidthExpandedDesktop !important;
    }
  }
  .topbar {
    > .top-navbar {
      padding: 0 15px 0 0;
      .holders {
        visibility: visible;
      }
      .navbar-collapse {
        flex-basis: 100%;
      }
    }
    .topbar-profile {
      #profile_button {
        .profile-info {
          display: flex;
        }
      }
    }
  }
}

@media screen and (max-width: 50rem) {
  .stats-bar {
    display: block;
  }
  .video-grid {
    grid-gap: 10px;
  }
  .video-grid.large {
    grid-gap: .5rem;
  }
  .container-fluid {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .page-content {
    padding-top: 10px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  // Login/Register
  .left-side-auth {
    display: none;
  }
  .full-page-center {
    display: block;
    overflow: scroll;
  }
  .right-side-auth {
    width: 100% !important;
    max-width: 480px;
    padding: 0 !important;
    padding-top: 30px !important;
    margin: 10px auto 100px;
    float: unset !important;
  }
}

// END Media Queries
